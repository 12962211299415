var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "text-container col-md-12" }, [
      _c("h4", { staticClass: "text" }, [
        _vm._v("\n      Great! "),
        _c("span", { staticClass: "company-name" }, [
          _vm._v(" " + _vm._s(_vm.company.name) + " "),
        ]),
        _vm._v(" has been added to your account.\n    "),
      ]),
    ]),
    _c("div", { staticClass: "col-md-12" }, [
      _c("div", { staticClass: "image-container" }, [
        _c("picture", [
          _c("source", {
            attrs: {
              srcset: require("@images/illustrations/stageline/stageline_company_creation_animation.webp"),
              type: "image/webp",
            },
          }),
          _c("img", {
            staticClass: "animation",
            attrs: {
              src: require("@images/illustrations/stageline/stageline_company_creation_animation.gif"),
              alt: "Company Created",
            },
          }),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "button-nav-container col-md-12 mt-4" }, [
      _c(
        "button",
        {
          staticClass: "continue-btn btn btn-primary",
          attrs: { "aria-label": "continue button" },
          on: {
            click: function ($event) {
              return _vm.navigateToNext()
            },
          },
        },
        [_vm._v("\n      Continue\n    ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }