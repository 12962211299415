<template>
  <div class="row">
    <div class="text-container col-md-12">
      <h4 class="text">
        Great! <span class="company-name"> {{ company.name }} </span> has been added to your account.
      </h4>
    </div>

    <div class="col-md-12">
      <div class="image-container">
        <picture>
          <source
            :srcset="require('@images/illustrations/stageline/stageline_company_creation_animation.webp')"
            type="image/webp"
          >
          <img
            :src="require('@images/illustrations/stageline/stageline_company_creation_animation.gif')"
            alt="Company Created"
            class="animation"
          >
        </picture>
      </div>
    </div>

    <div class="button-nav-container col-md-12 mt-4">
      <button
        class="continue-btn btn btn-primary"
        aria-label="continue button"
        @click="navigateToNext()"
      >
        Continue
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CompanyCreated',
  computed: {
    ...mapGetters('stageline', [
      'company',
    ]),
  },
  methods: {
    async showDecisionTree() {
      await this.$router.push({ name: 'stageline-v2-decision-tree', params: { companyId: this.company.id } })
    },
    async navigateToFormsLibrary() {
      await this.$router.push({ name: 'companySelection' })
    },
    async navigateToNext() {
      if (this.$route.params.origin === 'formsLibrary') {
        await this.navigateToFormsLibrary()
      } else {
        await this.showDecisionTree()
      }
    },
  },
}
</script>

<style scoped lang="scss">

.row {
  text-align: center;
}
.text-container {
  margin-bottom: 0.188em;
  text-align: center;

  .text {
    display: inline-block;
    text-align: left;
  }
  .company-name {
    color: #000864;
  }
}

div.button-nav-container {

  .continue-btn {
    background: $ct-ui-primary;
    border-color: $ct-ui-primary;

    &:hover {
      background: $ct-ui-primary-dark;
      border-color: $ct-ui-primary-dark;
    }
  }
}

.image-container {
  display: flex;
  justify-content: center;
  flex: 0 1 50%;

  picture {
    margin-bottom: 1.25em;

    .animation {
      width: 75vw;
      max-width: 700px;
    }
  }
}
</style>
